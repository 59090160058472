.hackathonData {
  margin-top: 62px;
}

.hackathonDataPageBackArrow {
  width: 25px;
  display: inline;
  margin-left: 70px;
}

.hackathonDataHeader {
  max-width: 1160px;
}

.candidatesListNoData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.candidatesListNoDataText {
  font-weight: 600;
  margin-top: 50px;
  font-size: 14px;
  line-height: 14px;
  color: #3c3e4d;
}

.candidatesListNoDataDec {
  font-weight: 500;
  font-size: 12px;
  color: #b1b2b8;
  margin-top: 20px;
}

.hackathonNavButtonPrev,
.hackathonNavButtonNext {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: #e1dfe3;
  border: none;
  position: relative;
}

.hackathonNavButtonPrev>img {
  position: absolute;
  left: 6px;
  top: 5px;
}

.hackathonNavButtonNext>img {
  position: absolute;
  left: 8px;
  top: 5px;
}

.hackathonNavButtonPrev:hover,
.hackathonNavButtonNext:hover {
  background: #484a58 !important;
  cursor: pointer;
}

.hackathonDataContainer {
  background-color: #ffffff;
  padding-left: 30px;
  padding-right: 40px;
  margin-left: auto;
  overflow: hidden;
  max-height: calc(100vh - 90px);
}

.hackathonTotalRegistration {
  background: #e8f3fd;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px 33px;
}

.hackathonTotalRegistration>span {
  padding: 6px 24px;
  background: #cce8fd;
  color: #008af3;
  font-weight: 600;
  margin-right: 20px;
  font-size: 16px;
  border-radius: 50px;
}

.hackathonTotalSubmissions {
  background: #fff2e4;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px 20px;
}

.visitHackathonBtnActive {
  height: fit-content;
  padding: 5px 30px;
}

.visitHackathonBtnInactive {
  height: fit-content;
  padding: 5px 30px;
  background: #ff6f73 !important;
  border: 1px solid #ff6f73;
}

.visitHackathonBtn {
  border-radius: 50px;
  background: #000;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 20px;
  cursor: pointer;
}

.hackathonTotalSubmissions>span {
  padding: 6px 24px;
  background: #ffd8b6;
  margin-right: 20px;
  color: #ff9b4a;
  border-radius: 50px;
}

.hackathonStatusActiveDot {
  width: 7px;
  height: 7px;
  border-radius: 50px;
  background: #2bbd10;
  display: inline-block;
}

.hackathonStatusInactiveDot {
  width: 7px;
  height: 7px;
  border-radius: 50px;
  background: #ff6f73;
  display: inline-block;
}

.hackathonScheduleActive {
  background: #f1fbef;
  border-radius: 10px;
  padding: 9px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.hackathonScheduleInactive {
  background: #ffecec;
  border-radius: 10px;
  padding: 9px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.hackathonScheduleActive>img {
  width: 27px;
}

.hackathonDataSearch {
  background: #f2f3f4;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.hackathonDataSearchInput>input {
  all: unset;
}

.hackathonDataSearchInput {
  display: inline;
  padding: 7px 15px;
  background-color: #ffffff;
  border: 0.5px solid #f2f3f4;
  border-radius: 50px;
}

.hackathonDataSearchFilter {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  color: #3c3e4d;
}

.hackathonDataSortByFilter {
  position: absolute;
  display: flex;
  z-index: 1;
  flex-direction: column;
  right: 10px;
  top: 30px;
  background-color: #ffffff;
  color: #666666;
  text-align: left;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.hackathonDataSortByFilter>span:hover {
  background-color: #f2f3f4 !important;
  color: #000000 !important;
}

.hackathonDataSortByFilter>span {
  text-align: left;
  padding: 15px 20px;
}

.hackathonDataSortByFilter>img {
  width: 20px;
}

.hackathonInnerImg {
  width: 162px;
  height: 87px;
  border-radius: 5px;
}

.hackathonInfoContainer .hackathonTab {
  border-radius: 5px;
  background: #F1FBEF;
}

.hackathonInfoContainer .heading {
  color: #202224;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
}

.hackathonInfoContainer .hackathonSubmissionTab {
  border-radius: 10px;
  background: #FFF2E4;
}

.hackathonData .hackathonInfoHeader {
  width: 100%;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  border: 0.5px solid #e2e2e9;
}

.hackathonData .hackathonInfoHeader .hackathonInnerBanner {
  width: 182px;
  height: 107px;
  flex-shrink: 0;
  border-radius: 5px;
}

.hackathonData .hackathonInfoHeader .hackathonStatusContainer {
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  width: 100%;
}

.hackathonData .hackathonInfoHeader .hackathonStatusContainer .hStatusPill {
  width: 70px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #f1fbef;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hackathonData .hackathonInfoHeader .hackathonStatusContainer .hStatusPill p {
  color: #3c3e4d;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hackathonData .hackathonInfoHeader .hackathonTitle {
  color: #202224;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
  margin-top: 15px;
}

.hackathonData .hackthonProgress {
  height: 2px;
  width: 100%;
  background-color: #e4e4e5;
  margin-top: 15px;
  border-radius: 50px;
}

.hackathonData .participantsInfo {
  width: 100%;
  height: 39px;
  border-radius: 10px;
  border: 0.5px solid #d9d9d9;
  background: #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
  position: relative;
}

.hackathonData .participantsInfo p {
  color: #FFF;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
}

.toogleBtn {
  border: 2px solid #ACADAD;
  background: #fff;
  padding: 1px;
  width: 30px;
  height: 14px;
  border-radius: 20px;
  margin-left: 8px;
  cursor: pointer;
}

.shortListFont {
  font-size: 13px;
  font-weight: 500;
  color: #666;
}

.toogleBtn .toogleInner {
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background-color: #ACADAD;
}

.hackathonDataSearch .exportBtn {
  border-radius: 4.69px;
  background: #666;
  padding: 6px 15px;
  width: 142px;
  cursor: pointer;
}

.hackathonDataSearch .exportBtn span {
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}

.totalSubmissionTab {
  background-color: #FFF2E4;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 6px 14px;
  border-radius: 10px;
}

.totalSubmissionTabInner {
  background-color: #ff9b4a44;
  color: #FF9B4A;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 50px;
  min-width: 34px;
  padding: 0px 10px;
}

@media (min-width: 1920px) {
  .hackathonDataHeader {
    max-width: 1290px;
  }

  .hackathonDataSearchFilter {
    font-size: 15px;
  }

  .hackathonScheduleActive {
    font-size: 17px;
  }
}